<template>
  <div>
    <!-- Add a bold button -->
    <button class="ql-bold">
      Bold
    </button>
    <button class="ql-italic">
      Italic
    </button>
    <button
      class="ql-list"
      value="ordered"
    />
  </div>
</template>

<script>
export default {
  name: 'EditorToolbar',
}
</script>
